import React from 'react'
import Helmet from 'react-helmet'

import Contact from '../components/Contact'
import Layout from '../components/layout'

const Page = () => {
  const siteTitle = 'Psychoterapia Mosty - gabinet psychoterapii we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Psychoterapia indywidualna, terapia par oraz terapia rodzinna.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>"Ludzie budują za dużo murów, a za mało mostów"</h2>
            <p style={{ textAlign: 'right' }}>Isaac Newton</p>
          </header>
          {/* <p>
            Accumsan orci faucibus id eu lorem semper. Eu ac iaculis ac nunc
            nisi lorem vulputate lorem neque cubilia ac in adipiscing in curae
            lobortis tortor primis integer massa adipiscing id nisi accumsan
            pellentesque commodo blandit enim arcu non at amet id arcu magna.
            Accumsan orci faucibus id eu lorem semper nunc nisi lorem vulputate
            lorem neque cubilia.
          </p>
          <ul className="actions">
            <li>
              <a href="#" className="button">
                Learn More
              </a>
            </li>
          </ul> */}
        </section>

        <section id="three">
          <Contact id="glowna" />
        </section>
      </div>
    </Layout>
  )
}

export default Page
